<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новый аккаунт"
        create
        url-update="/user/update/:id"
        :url-back="backUrl"
    >
        <user-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import UserForm from '@/components/forms/UserForm.vue'
export default {
    name: 'UserCreate',
    components: { FormView, UserForm },
    computed: {
        queryRole () {
            return this.$route.query.role === 'undefined' ? null : this.$route.query.role
        },
        backUrl () {
            const baseUrl = '/user'
            return this.queryRole ? `${baseUrl}?role=${this.queryRole}` : baseUrl
        },
        updateUrl () {
            const baseUrl = '/user/update/:id'
            return this.queryRole ? `${baseUrl}?role=${this.queryRole}` : baseUrl
        }
    }
}
</script>